form {
    max-width: 500px;
    width: auto;
    margin: 10% auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 5%;
}

input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;

    &[type=text] {
        font-size: x-large;
        margin: 1em 0;
        padding-left: 0.5em;

    }

    &[type=submit] {
        font-size: x-large;
        font-weight: bold;
        border-color: #689B2F;
        background-color: white;

        &:hover {
            background-color: #689B2F;
            cursor: pointer;
        }
    }
}

h1 {
    margin-bottom: 5%;
}